<template>
  <div class="container">
    <div class="row parent-row">
      <div class="col-md-6 content-left">
        <div class="row header-row">

          <div class="col-lg-10 header">
            <p>{{ $t('views.bussiness_card') }}</p>
            <p class="main-header">{{ exhibitor.company }}</p>
          </div>
          <div class="col-lg-2"></div>

          <div class="col-md-5">
            <p>{{ exhibitor.company_address }}</p>
          </div>
          <div class="col-md-6">
            <p>{{ exhibitor.phone }}</p>
          </div>
          <div class="col-md-1"></div>

          <div class="col-md-6 col-sm-4"></div>
          <div class="col-md-6 col-sm-8">
            <div class="row share-btn place-holder-div"> <!-- remove place-holder-div when share btn is back -->
              <!-- <button class="btn">
                <img class="share-img" src="@/assets/Icons/Icons/Icon-Share.svg" height="19.92" width="18" /> 
                {{ $t('views.share') }}
              </button> -->
            </div>

            <div class="row save-btn place-holder-div"> <!-- remove place-holder-div when save btn is back -->
              <!-- <button class="btn">
                <img class="save-img" src="@/assets/Icons/Icons/Icon-Save.svg" height="16" width="16" />
                {{ $t('views.save') }}
              </button> -->
            </div>
          </div>

        </div>
        
        <div class="row contacts-row">
          <div class="contacts-container">
            <p class="contact-text">{{ $t('views.hafele_contact_persons') }}</p>

            <div class="contacts">
              <div v-for="(contact, index) in contacts" :key="index" class="contact-cards" @click="goToChat(contact)">
                  <div class="card-data">
                    <p class="p-0 m-0 main-text">{{ contact.company }}</p>
                    <p class="p-0 m-0 sub-text">{{ contact.first_name }} {{ contact.last_name }}</p>
                  </div>
                  <div class="exhibit-icon pull-right p-contact" >
                    <img class="icon-img" src="@/assets/Icons/Icons/Icon-Chat.svg" />
                  </div>

                </div>
              </div>
          </div>
        </div>
      </div>

      <div class=" col-md-6 content-right">
        
        <p class="search-text">{{ $t('views.hafele_exhibits') }}</p>
        <form class="d-flex flex-row search">
          <input
            class="form-control form-control-sm search-box"
            type="text"
            :placeholder="$t('views.search')"
            aria-label="Search"
            v-model="filter"
          />
          <span class="icon-holder m-0 p-0">
            <i class="fas fa-search yellow p-0 m-0 search-icon" aria-hidden="true"></i>
          </span>
        </form>

        <div class="row exhibit-row">
          <div class="exhibits-container d-flex flex-column">
            <div v-for="( exhibit, index ) in filteredExhibits" :class="[{'high-lighted': index == selectedIndex},'','mb-4']" :key="index">
              <div class="exhibit-cards " @click="openProductModal(exhibit.uuid, index)">

                <div class="exhibit-data">
                  <p v-html="exhibit.title" class="p-0 m-0 main-text"></p>
                </div>
                <div class="exhibit-icon pull-right" >
                  <img class="icon-img" src="@/assets/Icons/Icons/Icon-Exhibit.svg" />
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'exhibitors',
  props: {
    toggleShowChat: Function,
    setSelectedUser: Function,
    closeProduct: Function,
    showProduct: Boolean,
    toggleShowProduct: Function,
    selectedNote: String,
    setSelectedNote: Function,
    goToNotes: Function,
    selectedExhibit: Object,
    setSelectedExhibit: Function,
    closeNote: Function
  },
  data() {
    return {
      filter: '',
      exhibitor: {},
      contacts: [],
      exhibits: [],
      searchString: '',
      productId: '',
      selectedIndex: ''
    };
  },
  methods: {
    goToChat(user) {
      if(localStorage.getItem('user-roles').includes('exhibitor') || localStorage.getItem('user-roles').includes('organizer')) {
        this.$router.push('/exhibitor/' + user.uuid);
      } else {
        this.setSelectedUser(user.uuid);
        this.toggleShowChat();
      }
    },
    openProductModal(uuid, index) {
      this.selectedIndex = index;
      this.setSelectedExhibit({uuid: uuid});
      this.toggleShowProduct();
    }
  },
  watch: {
    '$route.params.id'(to) {
      this.$http.get('/exhibitor?uuid=' + to)
        .then( res => {
          this.exhibitor = res.data;
          this.contacts = res.data.contacts;
          this.exhibits = res.data.exhibits;
        })
        .catch();
    }
  },
  computed: {
    filteredExhibits() {
      return this.filter ?
        this.exhibits.filter(exhibit => exhibit.title.toLowerCase().includes(this.filter.toLowerCase()) || exhibit.short_text.toLowerCase().includes(this.filter.toLowerCase())) :
        this.exhibits;
    }
  },
  created() {
    this.$http.get('/exhibitor?uuid=' + this.$route.params.id)
      .then( res => {
        this.exhibitor = res.data;
        this.contacts = res.data.contacts;
        this.exhibits = res.data.exhibits;
      })
      .catch();
  }
};
</script>

<style lang='scss' scoped>

/* Heights Adjustments */

.container {
  height: 100% !important;
}

.parent-row {
  height: 100% !important;
  padding-bottom: 40px !important;
}

.content-left {
  height: 100% !important;
}

.contacts-row {
  height: 50% !important;
}

.contacts-container{
  height: 80% !important;
}

.contacts {
  height: 80% !important;
}

.content-right {
  height: 100% !important;
}

.exhibit-row {
  height: 87% !important;
}

.exhibits-container {
  height: 95% !important;
}

/* /Heights Adjustments */


.content-right {
  border-left: 1px solid #ffffff99;
}

.content-left {
  padding-left: 74px !important;
}

p {
  color: #ffffff;
  letter-spacing: 0.49px;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 4px;
}

.high-lighted {
  border-color: $secondaryColor !important;
}

/* Start of info styling */

.head {
  height: 50% !important;
}

.main-header {
  color: $secondaryColor;
  text-transform: uppercase;
  letter-spacing: 0.56px;
  font-weight: 300;
  font-size: 28px;
  margin-top: 14px;
  margin-bottom: 14px;
}
.info p {
  color: #ffffff;
  letter-spacing: 0.49px;
  font-weight: 500;
  font-size: 16px;
}

.btn {
  margin: auto;
  margin-left: 35% !important;
  width: 150px;
  height: 37px;
  background-color: $secondaryColor;
  padding: 0px !important;
  padding-left: 14px !important;
  text-align: left;
  font-size: 16px !important;
  font-weight: 500;
  letter-spacing: 0.15px;
  text-transform: none;
}

.place-holder-div {
  height: 37px;
}

.share-btn {
  margin-top: 90px;
}

.save-btn {
  margin-top: 16px;
}

.share-img {
  margin-right: 13.7px !important;
}

.save-img {
    margin-right: 13.7px !important;
}

/* End of info Styling */


/* Start of contact Styling */

.contacts-container {
  margin-top: 60px !important;
  width: 93%;
}

.contact-text {
  margin-left: 2.5%;
  margin-bottom: 20px !important;
}

.contacts {
  padding-right: 20px !important;
  overflow-y: scroll;
}

.contacts-container p {
  margin-bottom: 30;
}

.contact-cards {
  margin-top: 20px !important;
  margin-left: 18px!important;
  height: 65px;
  border: 1px white solid;
  width: 90%;
  margin: auto;
  padding-left: 1%;
}

/* End of contact Styling */


/* Start of Exhibits Styling */
.exhibits-container {
  margin-left: 3%;
  margin-top: 20px !important;
  padding-right: 10px !important;
  width: 90%;
  overflow-y: scroll;
}

.exhibit-cards {
  height: 100% !important;
  min-height: 42px !important;
  border: 1px white solid;
  cursor: pointer;
  position: relative;
  width: 90%;
  margin: auto;
  padding-left: 1%;

}
.exhibit-cards:hover {
  border-color: $secondaryColor;
}

.exhibit-cards:hover .exhibit-icon {
  background-color: $secondaryColor;
}

.search-text {
  margin-left: 6%;
}

i.search-icon:before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.search {
  margin-top: 3.2%;
  width: 83%;
  margin-left: 5%;
  color: #ffffff99;
}

.search-box {
  background-color: rgba(0, 0, 0, 0);
  border: 1px #ffffff99 solid;
  border-radius: 0px;
  height: 34px !important;
  color: white;
  font-weight: 400;
}

.search-box:focus {
  border-color: white;
}

.icon-holder {
  background-color: #ffffff99;
  width: 10%;
  height: 34px !important;
  position: relative;
  cursor: pointer;
}

.search-icon {
  color: black;
  margin: 0 !important;
  padding: 0 !important;
}

.icon:hover {
  cursor: pointer;
}

/* End of Exhibits Styling */

/* Cards with icons */

.exhibit-icon {
  background-color: white;
  width: 15% !important;
  height: 100% !important;
  position: relative;
}

.contact-cards:hover {
  cursor: pointer;
  border-color: $secondaryColor !important;
}

.contact-cards:hover .exhibit-icon {
  background-color: $secondaryColor !important;
}

.icon-img {
  width: 24px !important;
  height: 28px !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.p-contact {
  padding-top: 4% !important;
  padding-left: 4%;
}

.card-data {
  padding-top: 2.5%;
  padding-left: 3%;
  letter-spacing: 0.15px;
  margin-right: 0px !important;
  display: inline-block;
  width: 85%;
}

.exhibit-data {
  padding-left: 3%;
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 85%;
  white-space: pre-line;
}

/* /Cards with icons */

.main-text {
  width: 90%;
}

.sub-text {
  width: 90%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .content-left {
    padding-left: 5px !important;
  }

  .btn{
     margin-left: 7% !important;
     width: 130px !important;
  }

  img {
    width: 14px !important;
    height: 14px !important;
  }

  .save-img, .share-img {
    margin-right: 10px !important;
  }

  .share-btn {
    margin-top: 45px !important;
  }

  p {
    font-size: 11px !important;
  }

  .main-header {
    font-size: 25px !important;
  }

  .contact-cards {
    height: 50px !important;
  }

  .search {
    margin-left: 3% !important;
    width: 85%;
  }

  .search-text {
    margin-left: 3%;
  }

}

@media only screen and (min-width: 1030px) and (max-width: 1366px) { 
  .content-left {
    padding-left: 20px !important;
  }

  .header-row {
    height: 50% !important;
  }

  .btn {
    margin-left: 20% !important;
    width: 140px !important;
    height: 34px !important;
  }

  img {
    width: 15px !important;
    height: 15px !important;
  }

  .save-img, .share-img {
    margin-right: 11px !important;
  }

  .btn{
     margin-left: 30% !important;
     width: 130px !important;
  }

  .share-btn {
    margin-top: 25px !important;
  }

  .contact-text {
    margin-left: 4.5%;
    margin-bottom: 20px !important;
  }

  p {
    font-size: 12px !important;
  }

  .main-header {
    font-size: 21px !important;
  }

  .contacts-row {
    height: 50% !important;
  }

  .contacts-container {
    height: 70% !important;
  }

  .contacts {
    height: 85% !important;
  }

  .contact-cards {
    height: 52px !important;
  }

  .contact-cards {
    height: 52px !important;
  }

  .search {
    margin-left: 4% !important;
    width: 84%;
  }

  .search-text {
    margin-left: 4%;
  }

  .exhibit-icon {
    background-color: white !important;
    width: 15% !important;
    height: 100% !important;
    padding: 2% 4%;
  }
}

@media only screen and (min-device-width : 360px) and (max-device-width : 640px) {
  .content-left {
    padding-left: 5px !important;
  }

  .btn{
     margin-left: 70% !important;
     width: 90px !important;
     font-size: 10px !important;
  }

  img {
    width: 8px !important;
    height: 8px !important;
  }

  .save-img, .share-img {
    margin-right: 5px !important;
  }

  .share-btn {
    margin-top: 0px !important;
  }

  p {
    font-size: 10px !important;
  }

  .main-header {
    font-size: 15px !important;
  }

  .contact-cards {
    height: 50px !important;
  }

  .search {
    margin-left: 3% !important;
    width: 85%;
  }

  .search-text {
    margin-left: 3%;
  }

  .exhibit-icon {
    padding: 3% 2%;
  }

  .contacts-container {
    margin-left: 10%;
  }

  .content-right {
    border-left: none;
  }
}  

</style>
